$color1: white;
$color2: rgba(190, 228, 220, 1);
$color3: rgba(5, 102, 125, 1);
$color4: rgba(109, 114, 133, 1);
$color5: rgba(77, 79, 99, 1);
$color6: rgb(124, 203, 181);
$color7: rgb(7, 81, 100);
$primary-font: "Rubik", "sans-serif";
$secondary-font: "Roboto", "sans-serif";
$tertiary-font: "Roboto", "sans-serif";
$serif-font: "Noto Serif", "serif";
$figure-font: "Quicksand", "sans-serif";

$image-regular: 100px;
$image-small: 75px;
$breakpoint-one: 360px;
$breakpoint-two: 500px;
