@import "variables.scss";

html {
  font-family: $primary-font;
  height: 100%;
  overflow: hidden;
}
body {
  background-color: $color3;
  color: $color1;
  height: 100%;
  margin: 0pt;
}
footer {
  background-color: $color2;
  bottom: 0px;
  font-family: $tertiary-font;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 2;

  & i {
    color: $color3;
    font-size: 8pt;
  }

  & .copyright {
    color: $color3;
    font-size: 8pt;
    opacity: 1;
    padding: 2px 8px;
    text-align: right;

    & .copyright-icon {
      font-size: 9pt;
      padding-right: 4px;
    }

    & .landing {
      opacity: 0;
    }
  }
}
li,
h4 {
  font-family: $secondary-font;
}
p {
  font-size: 10pt;
  font-weight: 300;

  &.long-text {
    padding-left: 12px;
  }
}
.annotation {
  font-size: 9pt;
  padding-left: 10px;
  position: absolute;

  & i {
    font-size: 12pt;
    transform: translateY(2px);
  }
}
strong {
  color: white;
  display: block;
  font-family: $secondary-font;
  font-size: 16pt;
  font-weight: 700;
}
body.landing {
  background-color: $color2;
}
#company_name.landing {
  bottom: 65%;
  //cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-size: 35pt;
  font-weight: 300;
  left: 50%;
  opacity: 0;
  position: fixed;
  text-transform: lowercase;
  transform: translate(-50%, 0%);
}
#tagline {
  font-family: "Fanwood Text", serif;
  font-size: 22pt;
  font-style: italic;
  font-weight: 300;
  left: 50%;
  opacity: 0;
  position: fixed;
  text-align: center;
  bottom: calc(65% - 60px);
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 28pt;
}
#subtagline {
  font-family: "Rubik", "sans-serif";
  font-size: 9pt;
  font-style: italic;
  font-weight: 300;
  left: 50%;
  opacity: 0;
  position: fixed;
  text-align: center;
  top: calc(50% + 50pt);
  transform: translate(-50%, -50%);
  width: 82%;
}
.category.landing {
  display: none;
}
.category.philosophy {
  height: fit-content;
}
#container.landing {
  width: 0%;
  position: absolute;
}
.text-content {
  border-left: solid 8px $color2;
  color: $color1;
  font-family: $primary-font;
  font-size: 12pt;
  font-weight: 150;
  margin: 0;
  padding: 0px 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#container.portfolio-mobile {
  height: 60vh;
  left: 10px;
  transform: translateX(0%);
}
#text-content.portfolio-mobile {
  float: right;
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  text-align: center;
  width: 100%;
}
#text-content.portfolio-mobile::-webkit-scrollbar {
  display: none;
}
.secondary-color {
  color: $color2;
}
.text-container.portfolio-mobile {
  display: inline-block;
  height: 30vh;
  padding-bottom: 15vh;
  padding-top: 15vh;
  position: relative;
  scroll-snap-align: start;
  text-align: left;
  width: calc(100% - 20px);
}
.card-nav.portfolio-mobile {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
}
.card-dots.portfolio-mobile {
  padding-left: 20px;
}
.selected.portfolio-mobile {
  color: rgb(124, 203, 181);
  font-size: 20px;
  list-style-type: circle;
  margin-left: 2px;
}
ul.portfolio-mobile {
  right: 0px;
}
p a {
  color: rgb(124, 203, 181);
}
#container.portfolio-desktop {
  height: 75vh;
}
#text-content.portfolio-desktop {
  float: right;
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  text-align: center;
  width: 50%;
}
#text-content.portfolio-desktop::-webkit-scrollbar {
  display: none;
}
#thumbnail.portfolio-desktop::-webkit-scrollbar {
  display: none;
}
.thumbnails.portfolio-desktop {
  height: 29vh;
  padding-bottom: 23vh;
  padding-top: 23vh;
  position: relative;
  scroll-snap-align: start;
}
.thumbnail-photo.portfolio-desktop {
  height: fit-content;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.thumbnail-width {
  display: inherit;
  margin: 0 auto;
  width: 100%;
  max-height: 130pt;
  max-width: 130pt;
}
.watermark {
  font-size: 28px;
  opacity: 0.15;
  position: absolute;

  &.end-corner {
    margin-left: -20px;
  }
}

#third-thumbnail-image {
  width: 80%;
}
.text-container.portfolio-desktop {
  display: inline-block;
  height: 29vh;
  padding-bottom: 23vh;
  padding-top: 23vh;
  position: relative;
  scroll-snap-align: start;
  text-align: left;
  width: calc(100% - 20px);
}
.thumbnail-nav.portfolio-desktop {
  left: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
}
.card-nav {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
}
li.portfolio-desktop:hover {
  color: rgb(124, 203, 181);
  cursor: pointer;
}
.selected.portfolio-desktop {
  color: rgb(124, 203, 181);
  font-size: 20px;
  list-style-type: circle;
  margin-left: 2px;
}
ul {
  right: 0px;
}
#button-container.portfolio-mobile {
  bottom: 6%;
  padding: 5%;
  position: absolute;
  width: 90%;
}
#button1 {
  position: 5%;
  position: initial;
}
#button2 {
  position: 30%;
  position: initial;
}
#button3 {
  position: 30%;
  position: initial;
}
.thumbnail-button.portfolio-mobile {
  display: inline-block;
  opacity: 0.4;
  position: relative;
  width: 30%;
}
.button-photo.portfolio-mobile {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#saltise-logo {
  height: 10vh;
  width: auto;
}
#opieva-logo {
  height: 10vh;
  width: auto;
}
span.selected.portfolio-mobile {
  opacity: 1;
}
.navigation {
  overflow: visible;
  z-index: 10;
}
#content {
  height: 80%;
}
.clear-both {
  clear: both;
}
.footnote {
  font-size: smaller;
}
.highlight {
  color: $color6;
  font-weight: 350;
}
#gallery1 {
  display: block;
  margin: auto;
  overflow: visible;
}
#workflow {
  display: block;
  margin: auto;
  overflow: visible;
}
#heading {
  background-color: $color1;
  color: $color3;
  font-family: $tertiary-font;
  font-size: 32pt;
  font-weight: 500;
  left: -100%;
  position: absolute;
  text-transform: uppercase;
  top: 66px;
  width: 100%;
  z-index: 2;

  & span {
    margin-left: 20px;
    vertical-align: middle;
  }

  & > img {
    display: none;
    padding-left: 22px;
    transform: translateY(6px);
  }
}
#container.mission {
  overflow-x: visible;
  overflow-y: visible;
  position: absolute;
  z-index: 1;
  width: 100%;
}
#container.team {
  position: absolute;
  z-index: 1;
}
#company_name {
  bottom: 11%;
  font-family: $secondary-font;
  font-size: 35pt;
  font-weight: 300;
  left: -4%;
  opacity: 0;
  position: fixed;
  text-transform: lowercase;
}
.logo {
  bottom: 64px;
  left: -43px;
  opacity: 0;
  position: fixed;
  transform: rotate(-90deg) translate(0%, 0%);
  width: 150px;
  z-index: 2;
}

.btn-wrapper {
  height: 10vh;
  min-height: 150px;
  position: fixed;
  top: calc(50% + 135pt);
  transform: translate(-50%, -50%);
  left:50%
}

.flashing {
}

.btn {
  border-radius: 4px;
  border: 2px solid white;
  color: white;
  display: inline-block;
  margin: 0 .25em;
  overflow: hidden;
  padding: 12px 60px 12px 0px;
  position: relative;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  opacity: 0;
  .btn-content {
    font-size: 10pt;
    line-height: 1.2;
    padding: 0 14px;
    position: relative;
    right: 0;
    transition: right 300ms ease;
    display: block;
    text-align: center;
    text-transform: uppercase;
  }
  .icon-btn {
    border-left: 1px solid white;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transition: all 300ms ease;
    transform: translateY(-50%);
    width: 58px;
    height: 70%;
    text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      font-size: inherit;
      text-rendering: auto;
      font-weight: 500;
    }
  }
  &:hover {
    .btn-content {
      right: 100%;
    }
    .icon-btn {
      border-left: 0;
      font-size: 1.8em;
      width: 100%;
    }
    &:after {
      opacity: .2;
    }
  }

}

.wave{
  display: block;
  overflow: visible;
  position: absolute;
  bottom: 0;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
}

#container {
  left: 50%;
  padding-bottom: 60px;
  position: fixed;
  top: 140px;
  transform: translate(-50%, 0);
  width: calc(100% - 30px);
}
#Mission {
  background-color: rgba(5, 102, 125, 0.9);
  border-color: white;
  border-radius: 10px;
  border-style: solid;
  border-width: 0.5px;
  font-family: $primary-font;
  font-size: 11pt;
  font-weight: 300;
  margin: 25px auto;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  width: 80%;
}
#work {
  margin: auto;
  max-width: 700px;
}
.project {
  font-family: $secondary-font;
  font-size: 10pt;
  font-weight: 300;
}
.tiny-icon {
  font-size: 8pt;
}
.clickable {
  color: white;
  cursor: pointer;
  float: left;
  font-size: 32px;
  margin-left: 5px;
  transform: translateY(0px);
}
.blurb {
  color: white;
  font-size: 10pt;
  min-height: 32px;
  padding-left: 45px;
}
.navigation {
  cursor: pointer;
  font-family: "Material Icons";
  font-size: 28px;
  position: fixed;
  top: 2px;
}
.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: "Material Icons";

  /* Support for IE. */
  font-feature-settings: "liga";

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}
#icon {
  cursor: pointer;
}
.icon1 {
  right: 0px;
}
.icon2 {
  right: 50px;
}
.icon3 {
  right: 100px;
}
.icon4 {
  right: 150px;
}
.icon5 {
  right: 200px;
}
.icon6 {
  right: 250px;
}
.icon7 {
  right: 300px;
}

#button-language
{    
    visibility: hidden;
}

.highlights {
  display: none;
  font-family: $secondary-font;
  font-size: 10px;
  opacity: 0;
}
table.sample-data {
  position: relative;
}
#sample-data {
  padding-top: 20px;
}
table,
th,
td {
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-family: "Rubik", serif;
  font-size: 8pt;
  padding: 2px 10px;
}
table {
  border-collapse: collapse;
  float: left;
}
td:hover {
  background-color: rgb(124, 203, 181);
  color: rgba(5, 102, 125, 1);
}
#prompt {
  bottom: 40px;
  cursor: pointer;
  position: fixed;
  right: 30px;
}
.portrait {
  border: 1px solid $color2;
  border-radius: 50%;
  float: left;
  height: $image-small;
  margin-right: 15px;
  object-fit: cover;
  width: $image-small;
}
#thumbnail {
  float: left;
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  width: 50%;
}
em.footnote {
  color: white;
  display: block;
  text-align: right;
}
.portrait-details {
  display: inline-block;
  width: calc(100% - #{$image-small} - 17px);
}
.portrait-name {
  display: inline;
  font-family: $tertiary-font;
  font-weight: 400;
  line-height: normal;
}
.portrait-notes {
  color: $color2;
  font-size: 0.85em;
  font-style: italic;
  font-weight: 300;
  line-height: 1.1;
  padding-top: 4px;
  width: 100%;
}
.portrait-title {
  display: inline;
  font-size: 0.85em;
  font-weight: 300;
  line-height: 1.5;
}
.portrait-mail {
  display: inline;
}
#living-data {
  display: block;
  left: 50%;
  overflow: visible;
  position: absolute;
  z-index: -2;
  background-color: $color7;
}
#living-data.mission {
  transform: translate(-50%, -40pt);
}
#living-data.landing {
  transform: translate(-50%, -60pt);
}
#text-box {
  border-color: white;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  fill: white;
  filter: drop-shadow(2px 4px 12px black);
  font-family: $primary-font;
  font-size: 8pt;
  font-weight: 300;
  left: 5px;
  max-width: 80%;
  padding: 15px;
  position: fixed;
  top: 10px;
  width: fit-content;
}
.nodes circle {
  pointer-events: all;
  stroke: white;
  stroke-width: 1px;
  filter: drop-shadow(0.75px 0.75px 2px black);
}
.links line {
  stroke: white;
  stroke-width: 1px;
  filter: drop-shadow(0.75px 0.75px 2px black);
}
#region {
  fill: white;
  font-family: $secondary-font;
  font-size: 10pt;
}
.team-headshots {
  width: 100%;
}
.headshot {
  font-family: $primary-font;
  font-size: 10pt;
  font-weight: 400;
  line-height: 0.8;
  margin: 0 auto;
  padding-right: 0px;
  padding-top: 20px;

  & i {
    font-size: 14px;
    padding-left: 1px;
    padding-right: 4px;
    padding-top: 6px;
    float: left;
  }
  & span {
    text-decoration: none;
    padding-left: 1px;
    padding-right: 4px;
    padding-top: 6px;
    line-height: 25.5pt;
  }
  & a{
    color: white;
    text-decoration: none;
    font-size: 0.85em;
    font-weight: 300;
    padding-top: 4px;
    width: 100%;
  }

  &:last-child {
    padding-bottom: 40px;
  }
}
.notes {
  color: $color2;
  font-size: 8pt;
  line-height: 0.8;
}
#pie-title {
  fill: white;
  font-family: $secondary-font;
  font-size: 10pt;
}
#title {
  font-size: 10pt;
}
.figure-caption {
  float: right;
  font-family: $secondary-font;
  font-size: 8pt;
  font-weight: 300;
  padding-top: 20px;
  text-align: right;
  width: 80%;
}
.plot-label {
  fill: white;
  font-family: $figure-font;
  font-size: 6pt;
}
.data-label {
  fill: white;
  font-family: $figure-font;
  font-size: 6pt;
}
i {
  color: $color2;
  font-size: 18px;
  margin: -1px;
}
a {
  color: white;
  font-style: normal;
  text-decoration: underline;
}
.graph-label {
  font-size: 7pt;
}
.inline {
  vertical-align: middle;
}
.italics {
  font-style: italic;
}

@media (min-width: $breakpoint-one) {
  p {
    font-size: 11pt;
  }

  .headshot {
    font-size: 12pt;

    & i {
      font-size: 18px;
      padding-left: 1px;
      padding-right: 3px;
      padding-top: 8px;
    }
  }

  #company_name {
    font-size: 40pt;
  }

  #heading{
    font-size: 36pt ;
  }
}

@media (min-width: $breakpoint-two) {
  p {
    font-size: 12pt;

    &.long-text {
      border-left: solid 8px $color2;
    }
  }

  .portrait {
    height: $image-regular;
    width: $image-regular;
  }
  .portrait-details {
    width: calc(100% - #{$image-regular} - 18px);
  }

  #container {
    top: 170px;
    width: 80%;
  }

  #heading{
    font-size: 40pt ;
  }
}

@media (max-width: 800px) and (orientation: portrait) {
  #container.cegeps {
    top: 80vw;
  }
  #tagline-container {
    overflow: visible;
  }
  #splash-svg {
    height: 100vw;
  }
}

@media (max-height: 600px) {
  .logo {
    opacity: 0;
  }
}




@media (max-width: 800px) and (orientation: portrait) {
  .logo {
    opacity: 0;
  }
  .text-content {
    font-size: 10pt;
  }
  #tagline{
    font-size: 20pt;
  }
  .btn{
    .btn-content{
      top: calc(50% + 140pt);
    }
  }
  @media (max-height: 600px) {
    #text-content.portfolio-mobile {
      height: 50vh;
    }
    .text-container.portfolio-mobile {
      padding-bottom: 10vh;
      padding-top: 10vh;
    }
    .text-content {
      font-size: 8pt;
    }
  }
}

@media (min-width: 650px) {
  footer {
    background-color: rgba($color3, 0);
    bottom: 6px;
    left: -6px;

    & i {
      color: $color1;
    }

    & .copyright {
      color: $color1;
    }

    /*& .name {
     display: none;
    }*/
  }
  .logo {
    opacity: 1;
  }
  #heading > img {
    display: inline;
  }
}

/* Large screens */
@media (min-width: 800px) {
  #company_name {
    font-size: 60pt;
  }
  #heading {
    font-size: 42pt;
    top: 35px;
  }
  #tagline {
    font-size: 26pt;
  }
  #subtagline {
    font-size: 12pt;
    max-width: 498pt;
  }
  .btn {
    .btn-content {
      font-size: 11pt;
      padding: 0 10px;
    }
  }
  #title {
    font-size: 12pt;
  }
  .highlights {
    display: block;
    font-size: 12px;
  }
  .text-content {
    line-height: 1.4;
  }
  #Mission {
    font-size: 14pt;
    margin: 40pt auto;
  }
  .blurb {
    font-size: 12pt;
  }
  #text-box {
    font-size: 11pt;
    max-width: 400px;
  }
  .figure-caption {
    font-size: 11pt;
  }
  #region {
    font-size: 12pt;
  }
  #pie-title {
    font-size: 12pt;
  }
  #living-data {
    transform: translate(-50%, -40pt);
  }
  .plot-label {
    font-size: 9pt;
  }
  .data-label {
    font-size: 7pt;
    margin-top: 2pt;
  }
  .headshot {
    margin-left: 20px;
  }
  .team-headshots {
    margin: 0 auto;
    width: 80%;
  }
  .graph-label {
    font-size: 10pt;
  }
  .notes {
    color: $color2;
    font-size: 9pt;
    line-height: 0.8;
  }
  .email {
    font-size: 16px;
    transform: translateY(2px);
  }
  .project {
    font-size: 12pt;
  }
  #container {
    top: 127px;
    width: 80%;
  }
}
